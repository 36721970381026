const NavBar = () => {
  return (
    <div className="fixed top-0 left-0 w-screen h-16 shadow-lg flex items-center justify-between px-4 py-2">
      <div className="w-12 h-12 bg-gray-100 rounded-full flex items-center justify-center">
        <div className="font-bold text-transparent text-3xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
          S
        </div>
      </div>
    </div>
  );
};

export default NavBar;
