import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Empty from "./pages/Empty";
import NavBar from "./components/Navbar";

function App() {
  return (
    <>
      <NavBar />
      <div className="mt-16">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="*" element={<Empty />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
